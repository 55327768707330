import { Container, Col } from "react-bootstrap";
import * as React from "react";
import { StyledAngleHeroRow } from "../one-off/InnovationHero/InnovationHero";
import styled from "styled-components";

const Wrap = styled(Container)`
  position: absolute;
  padding: 0;
  overflow: hidden;
  clip-path: polygon(0 0%, 100% 0, 100% 65%, 0% 100%);
  z-index: 9;
  @media only screen and (max-width: 991px) {
    clip-path: polygon(0 0%, 100% 0, 100% 83%, 0% 95%);
  }
  @media only screen and (max-width: 600px) {
    clip-path: polygon(0 0%, 100% 0, 100% 83%, 0% 90%);
  }
`;

export const GradientAngle = ({ height }: { height: number }) => {
  return (
    <Wrap fluid>
      <StyledAngleHeroRow>
        <Col style={{ minHeight: height * 1.125 }}></Col>
      </StyledAngleHeroRow>
    </Wrap>
  );
};
