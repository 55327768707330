import * as React from "react";
import { Fade } from "react-awesome-reveal";
import { UnorderedList } from "../one-off/InnovationHero/InnovationHero";
import { Typography } from "./Typography";
import { colors } from "./variables";

const Bullets = ({ bullets, title }: { bullets: string[]; title: string }) => {
  return Array.isArray(bullets) ? (
    <div style={{ padding: "2rem 0" }}>
      {title ? (
        <Fade direction="left">
          <Typography.H2 style={{ color: colors.blue.hex }}>
            {title}
          </Typography.H2>
        </Fade>
      ) : null}

      <UnorderedList>
        <Fade cascade triggerOnce duration={600}>
          {bullets.map((bullet, i) => {
            return (
              <li key={bullet + i}>
                <Typography.P style={{ color: colors.text.hex }}>
                  {bullet}
                </Typography.P>
              </li>
            );
          })}
        </Fade>
      </UnorderedList>
    </div>
  ) : null;
};

export default Bullets;
