import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { GradientAngle } from "../ui/GradientAngle";
import { Typography } from "../ui/Typography";
import { colors } from "../ui/variables";
import { useRef, useEffect } from "react";
import { Bounce, Fade } from "react-awesome-reveal";
import { Background, Parallax } from "react-parallax";
import { Img, WmkImage } from "wmk-image";
import { GradientOverlay } from "../ui/Overlays";

const HeroContent = styled.div`
  position: relative;
  z-index: 10;
  padding: 0 3vw 0 0;
  color: ${colors.white.hex};
`;
const DynImageWrap = styled.div<{ size: number; parallax: any }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper,
  .react-parallax {
    height: ${({ size, parallax }) => {
      const height = size ? size : 0;
 
      return parallax ? height * 1.5 : height;
    }}px;
    .react-parallax-background-children {
      width: 100%;
      // left: 0;
    }
  }
`;

const AngleClip = styled.div<{ size: number; blog: boolean | unknown }>`
  // clip-path: polygon(0 0%, 100% 0, 100% 55%, 0% 90%);
  clip-path: polygon(0 0%, 100% 0, 100% 65%, 0% 100%);
  @media screen and (max-width: 450px) {
    clip-path: polygon(0 0%, 100% 0, 100% 75%, 0% 100%);
  }
  position: absolute;
  height: ${({ size, blog }) =>
    size && blog ? `calc(${size}px + 5rem)` : size ? `${size}px` : `0px`};
  min-height: ${({ size }) => size}px;
  width: 100vw;
  ${({ blog }) => (blog ? `padding: 10vh 0` : ``)};
`;

const HeaderWrapper = styled(Container)<{ size: number }>`
  height: 100%;
`;

const GradientAngleHeader = ({
  title,
  text,
  Aux,
  height,
  setter,
  white,
  image,
  blog
}: {
  title?: string;
  text?: string;
  Aux?: React.FC;
  height: number;
  setter: React.Dispatch<React.SetStateAction<number>>;
  white?: boolean;
  image?: Img;
  blog?: boolean;
}) => {
  const innerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const current = innerRef.current;
    const handleResize = () => {
      const rect = current ? current.getBoundingClientRect() : null;
      setter(rect.height);
    };
    // handleResize();
    const timeout = setTimeout(handleResize, 0);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  });

  return (
    <HeaderWrapper
      fluid
      style={{
        position: "relative",
        background: white ? "none" : colors.silver.hex,
        padding: 0,
        zIndex: 10,
        height: "fit-content"
      }}
      size={height}>
      {image && (
        <AngleClip size={height} blog={blog}>
          <DynImageWrap size={height} parallax={image}>
            <Parallax strength={100}>
              <Background>
                <WmkImage image={image} />
              </Background>
            </Parallax>
            <GradientOverlay />
          </DynImageWrap>
        </AngleClip>
      )}
      {!image && (
        <Fade triggerOnce>
          <GradientAngle height={height} />
        </Fade>
      )}
      <Container>
        <Row
          style={{
            position: "relative",
            zIndex: 10,
            padding: "22vh 0 4vh 0"
          }}
          ref={innerRef}>
          <Col xs={12}>
            <Fade direction="left" delay={200} triggerOnce>
              <Typography.H1
                style={{
                  color: colors.white.hex,
                  position: "relative",
                  zIndex: 300,
                  padding: `3rem 0 1.5rem 0`
                }}>
                {title}
              </Typography.H1>
            </Fade>
          </Col>

          <Col xs={12} md={6}>
            <HeroContent>
              <Fade delay={500} triggerOnce>
                <Typography.P>{text}</Typography.P>
              </Fade>
              <br />
            </HeroContent>
          </Col>
          {Aux ? (
            <Col
              xs={12}
              md={6}
              style={{
                position: "relative",
                zIndex: 10,
                padding: "0 6vw 0 1vw"
              }}>
              <Bounce delay={400} triggerOnce>
                <Aux />
              </Bounce>
            </Col>
          ) : null}
        </Row>
      </Container>
    </HeaderWrapper>
  );
};

export default GradientAngleHeader;
