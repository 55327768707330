import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import { RatioPlaceholder } from "./RatioPlaceholder";
import { Typography } from "../../ui/Typography";
import { colors } from "../../ui/variables";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import PepperButton from "../../ui/MainButton";
import { StaticImage } from "gatsby-plugin-image";
import ImageCarousel from "../../ui/ImageCarousel/ImageCarousel";
import { GradientOverlay } from "../../ui/Overlays";
import { Background, Parallax } from "react-parallax";
import { Machine } from "../../../classes/Machine";
import { Page } from "../../../classes/Page";
import { Img, WmkImage } from "wmk-image";
import { MachinesQuery } from "../../../fragments/NodeMachinesFields";
import { ReactElement } from "react-markdown/lib/react-markdown";

const VideoWrap = styled.div<{
  height: number;
  width: number;
  parallax: string;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  .react-parallax {
    height: ${({ height, parallax }) => {
      return parallax && height ? height * 1.5 : height;
    }}px;
    ${({ width, parallax }) =>
      parallax && width ? `width: ${width}px;` : `width: 100%;`}
    .react-parallax-background-children {
      width: 100%;
      left: 0;
    }
  }
`;

const PlaceholderWrap = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 100vh;
  }
`;

export const VideoPlaceholder = () => (
  <PlaceholderWrap>
    <StaticImage
      src="./pepper_bantam_vid_still.jpg"
      alt="Watch the Bantam Grading System Video"
    />
  </PlaceholderWrap>
);

export const BantamCarousel = ({ images }: { images: Img[] }) => {
  const imagesArray = images.map((img, i) => {
    // const gatsbyImageData = get(img, "gatsby");
    // const title = get(img, "title");
    // return () => <GatsbyImage image={gatsbyImageData} alt={title} />;
    return () => <WmkImage image={img} />;
  });
  return <ImageCarousel images={imagesArray} />;
};

const AngledWrap = styled.div`
  position: relative;
  margin-bottom: -23vh;
  bottom: 3vh;
  @media and screen (max-width: 700px) {
    padding-bottom: 5vh;
  }
`;

const VideoBackground = ({ url }: { url: string }) => {
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const ratioRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const current = ratioRef.current;
    const handleResize = () => {
      const rect = current ? current.getBoundingClientRect() : null;
      setVideoWidth(rect.width);
      setVideoHeight(rect.height);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    // const timeout = setTimeout(handleResize, 300);
    return () => {
      window.removeEventListener("resize", handleResize);
      // clearTimeout(timeout);
    };
  });
  return (
    <div style={{ position: "relative", height: videoHeight }}>
      <VideoWrap height={videoHeight} width={videoWidth} parallax={url}>
        <Parallax strength={200}>
          <Background>
            {videoWidth >= 740 ? (
              <ReactPlayer
                url={url}
                muted
                loop
                playing
                width={
                  videoWidth >= 900
                    ? videoWidth
                    : videoHeight * 2.77777777778 * 2.25
                }
                height={videoWidth >= 900 ? videoHeight : videoHeight * 2.25}
                style={{
                  position: "absolute",
                  top: 0,
                  right: videoWidth >= 900 ? 0 : "-110vw"
                }}
              />
            ) : (
              <VideoPlaceholder />
            )}
          </Background>
        </Parallax>
      </VideoWrap>
      <RatioPlaceholder ref={ratioRef} />
    </div>
  );
};

const StyledHeroText = styled(Typography.P)`
  max-width: 50vw;
  strong {
    display: block;
    font-size: 30px;
    text-decoration: underline;
    line-height: 1.7;
  }
`;

export const StyledAngleHeroRow = styled(Row)`
  background: rgb(35, 103, 186);
  background: linear-gradient(
    0deg,
    rgba(35, 103, 186, 1) 0%,
    rgba(42, 125, 225, 1) 100%
  );
  display: flex !important;
`;

const AngleTop = styled.div`
  clip-path: polygon(0 18%, 100% 0, 100% 100%, 0% 100%);
  @media only screen and (max-width: 767px) {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  }
`;

const AngledSection = ({ children }: { children: ReactElement }) => {
  return (
    <AngledWrap>
      <AngleTop style={{ position: "absolute", bottom: "20vh", width: "100%" }}>
        {children}
      </AngleTop>
      <div style={{ visibility: "hidden" }}>{children}</div>
    </AngledWrap>
  );
};

const MachineBlock = ({
  machine,
  block = false
}: {
  machine: MachinesQuery;
  block?: boolean;
}) => {
  const mac = new Machine(machine);
  const displayTitle = mac.displayTitle;
  const description = mac.description;
  const applications = mac.applications ? mac.applications : [];
  const to = mac.link;
  const images = mac.images ? mac.images : [];

  return (
    <>
      <Col
        sm={12}
        md={6}
        style={{
          padding: block ? `6rem` : `30vh 2rem 0 5rem`,
          marginTop: block ? 0 : `-5vh`,
          marginBottom: block ? 0 : `5vh`
        }}>
        <Row className="flex-column">
          <Col style={{ color: colors.white.hex }}>
            <Typography.H4>{displayTitle}</Typography.H4>
            <Typography.P>{description}</Typography.P>
            {applications &&
            Array.isArray(applications) &&
            applications.length ? (
              <div style={{ padding: "1rem 0" }}>
                <Typography.H6>Applications</Typography.H6>{" "}
                <Typography.P>
                  <UnorderedList style={{ margin: `.5rem 0 1rem 0` }}>
                    {applications.map((app, j) => (
                      <li key={`${app}-${j}`} style={{ fontSize: `.9rem` }}>
                        {app}
                      </li>
                    ))}
                  </UnorderedList>
                </Typography.P>
              </div>
            ) : null}
            <PepperButton reverse to={to.to}>
              View Machine
            </PepperButton>
          </Col>
        </Row>
      </Col>
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: "5rem"
        }}
        sm={12}
        md={6}>
        <BantamCarousel images={images} />
      </Col>
    </>
  );
};

export const UnorderedList = styled.ul`
  margin: 1rem;
  li {
    margin: 0;
  }
`;

const InnovationHero = ({ page }: { page: Page }) => {
  const url = page.video.url;
  const machines = [...page.machines].reverse();
  const first = machines.pop();
  return (
    <Container fluid style={{ padding: 0 }}>
      <Row className="flex-column" style={{ margin: 0 }}>
        <Col style={{ position: "relative", padding: 0, overflow: "hidden" }}>
          <VideoBackground url={url} />
          <GradientOverlay />
          <div
            style={{
              position: "absolute",
              top: "-1rem",
              left: 0,
              height: "100%",
              width: "100%",
              color: colors.white.hex,
              padding: `30vh 0 2rem 0`
            }}>
            <Container fluid style={{ padding: "0 6vw" }}>
              <Typography.H1>{page.title}</Typography.H1>
              <StyledHeroText>
                <ReactMarkdown children={page.header.text} />
              </StyledHeroText>
              <PepperButton reverse to={page.cta.to} target={page.cta.target}>
                {page.cta.text}
              </PepperButton>
            </Container>
          </div>
        </Col>
        {first ? (
          <Col style={{ padding: 0 }}>
            <AngledSection>
              <StyledAngleHeroRow
                style={{ padding: "1rem", display: "block !important" }}>
                <MachineBlock machine={first} />
              </StyledAngleHeroRow>
            </AngledSection>
          </Col>
        ) : null}
      </Row>
      {Array.isArray(machines) ? (
        <StyledAngleHeroRow>
          {machines.map((m, i) => {
            return <MachineBlock key={m.displayTitle + i} machine={m} block />;
          })}
        </StyledAngleHeroRow>
      ) : null}
    </Container>
  );
};

export default InnovationHero;
