import * as React from "react";
import { forwardRef } from "react";
import ratio from "./16x9_Transparent.png";

export const RatioPlaceholder = forwardRef<HTMLImageElement>((props, ref) => {
  return (
    <img
      ref={ref}
      src={ratio}
      style={{ width: "100%", margin: 0, minHeight: "90vh" }}
      alt=""
    />
  );
});
