import * as React from "react";
import get from "lodash/get";
import { Table } from "react-bootstrap";
import { Typography } from "./Typography";
import { colors } from "./variables";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";
import { Bounce, Fade } from "react-awesome-reveal";

const StyledTr = styled.tr`
  display: flex;
  align-items: center;
  @media screen and (max-width: 740px) {
    td {
      border-bottom: 0px;
      box-shadow: none;
    }
  }
`;

const StyledTd = styled.td<{
  info?: string | boolean;
  infoWidth?: string;
  width?: string;
  mobileWidth?: string;
}>`
  padding: 0.25rem 1rem;
  width: ${({ info, infoWidth, width }) =>
    infoWidth && width ? (info === true ? infoWidth : width) : "50%"};
  @media only screen and (max-width: 767px) {
    width: ${({ info, infoWidth, mobileWidth }) =>
      infoWidth && mobileWidth
        ? info === true
          ? infoWidth
          : mobileWidth
        : "50%"};
  }
`;

/**
 *
 */
const BasicTable = ({
  pairs,
  title
}: {
  pairs: ({ key: string; value: string } | string)[];
  title: string;
}) => {
  const _pairs = pairs.map((p) => {
    if (typeof p === "string") {
      return {
        name: p,
        info: true
      };
    } else {
      return { name: p.key, info: p.value };
    }
  });
 
  return Array.isArray(pairs) && pairs.length ? (
    <>
      <Fade direction="left">
        <Typography.H2 style={{ color: get(colors, "blue.hex") }}>
          {title}
        </Typography.H2>
      </Fade>
      <Table style={{ fontFamily: "Inter, sans-serif" }}>
        <tbody>
          {_pairs.map((pair, i) => {
            const name = get(pair, `name`);
            const info = get(pair, `info`);
            const displayInfo = () => {
              if (info === true) {
                return (
                  <Bounce triggerOnce delay={150 * i}>
                    <FaCheck
                      style={{ padding: 0, color: get(colors, "blue.hex") }}
                    />
                  </Bounce>
                );
              } else {
                return info;
              }
            };
            return (
              <StyledTr
                key={name + i}
                style={{
                  background:
                    i % 2
                      ? get(colors, "lightBlue.hex")
                      : get(colors, "veryLightBlue.hex")
                }}>
                <StyledTd
                  info={info}
                  infoWidth="80%"
                  width="30%"
                  mobileWidth="50%">
                  {name}
                </StyledTd>
                <StyledTd
                  info={info}
                  infoWidth="20%"
                  width="70%"
                  mobileWidth="50%">
                  {info ? displayInfo() : null}
                </StyledTd>
              </StyledTr>
            );
          })}
        </tbody>
      </Table>
    </>
  ) : null;
};

export default BasicTable;
