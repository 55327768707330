import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Machine, Machines } from "../../../classes/Machine";
import { Row, Col } from "react-bootstrap";
import { Typography } from "../Typography";
import { colors } from "../variables";
import EquipPreview from "./EquipPreview";
import { Fade } from "react-awesome-reveal";
import { MachinesQuery } from "../../../fragments/NodeMachinesFields";
import { Tag } from "wmk-tag";

const getMatchingTags = (
  slug: string,
  tags: Tag[],
  all: { node: MachinesQuery }[]
) => {
  const data = new Machines(all);
  const hash = data.hash;
  return Array.isArray(tags)
    ? tags.reduce((matches, tag) => {
        const match = hash[tag.slug];
        if (match) {
          const filteredMatch = match.reduce((filtered, machine) => {
            if (machine.slug !== slug) {
              filtered.push(machine);
            }
            return filtered;
          }, []);
          matches = matches.concat(filteredMatch);
        }
        return matches;
      }, [])
    : [];
};

/**
 *
 */
const RelatedEquipment = ({ slug, tags }: { slug: string; tags: Tag[] }) => {
  const data: { all: { edges: { node: MachinesQuery }[] } } = useStaticQuery(
    query
  );
  const all = data.all.edges;
  const matches: Machine[] = getMatchingTags(slug, tags, all).slice(0, 4);
  return Array.isArray(matches) && matches.length ? (
    <Col>
      <Fade direction="left">
        <Typography.H2 style={{ color: colors.blue.hex }}>
          Related Equipment
        </Typography.H2>
      </Fade>
      <Row>
        {matches.map((match, i) => {
          const title = match.displayTitle;
          const apps = match.applications;
          const image = match.thumbnails[0];
          const link = match.link;
          return (
            <Col
              key={link.to + i}
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{ padding: "4vh 1rem" }}>
              <Fade direction="up" delay={i * 250}>
                <Row className="flex-column">
                  <EquipPreview
                    title={title}
                    apps={apps}
                    image={image}
                    link={link}
                    related
                  />
                </Row>
              </Fade>
            </Col>
          );
        })}
      </Row>
    </Col>
  ) : null;
};

export default RelatedEquipment;

const query = graphql`
  {
    all: allContentfulMachines {
      edges {
        node {
          ...NodeMachinesFields
        }
      }
    }
  }
`;
