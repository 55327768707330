import * as React from "react";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { useState } from "react";
import { colors } from "./variables";

const StyledSvg = styled.div`
  position: relative;
  z-index: 15;
  a {
    svg {
      path {
        fill: ${colors.blue.hex};
        stroke: ${colors.blue.hex};
        transition: all 0.3s ease;
      }
    }
    &:hover {
      svg {
        path {
          fill: ${colors.darkBlue.hex};
          stroke: ${colors.darkBlue.hex};
          transition: all 0.3s ease;
        }
      }
    }
  }
`;

const QuoteButton = () => {
  const [isHover, setIsHovered] = useState(false);
  const handleMouse = () => {
    setIsHovered(!isHover);
  };
  return (
    <StyledSvg>
      <WmkLink to="/contact">
        <svg
          id="Request_A_Quote_button"
          data-name="Request A Quote button"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="357.885"
          height="45.001"
          viewBox="0 0 357.885 45.001"
          onMouseEnter={handleMouse}
          onMouseLeave={handleMouse}>
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_2097"
                data-name="Rectangle 2097"
                width="81"
                height="45"
                transform="translate(12975 15244)"
                fill={colors.white.hex}
                stroke="#707070"
                strokeWidth="1"
              />
            </clipPath>
          </defs>
          <path
            id="Subtraction_6"
            data-name="Subtraction 6"
            d="M458.269,72.774H136.7l50.951-45h270.62v45Z"
            transform="translate(-100.384 -27.773)"
            fill={colors.blue.hex}
          />
          <text
            id="Request_a_quote"
            data-name="Request a quote"
            transform="translate(213 30)"
            fill={colors.white.hex}
            fontSize="22"
            fontFamily="Inter-Bold, Inter"
            fontWeight="700">
            <tspan x="-104.18" y="0">
              REQUEST A QUOTE
            </tspan>
          </text>
          <g
            id="Mask_Group_17"
            data-name="Mask Group 17"
            transform="translate(-12975 -15244)"
            clipPath="url(#clip-path)">
            <path
              id="Path_1103"
              data-name="Path 1103"
              d="M10928.952,13216.539l80.945-71.492"
              transform={
                isHover
                  ? `translate(2053.154 2085.207)`
                  : `translate(2046.154 2085.207)`
              }
              fill="none"
              stroke={colors.blue.hex}
              strokeWidth="7"
            />
          </g>
        </svg>
      </WmkLink>
    </StyledSvg>
  );
};

export default QuoteButton;
