import { graphql } from "gatsby";
import * as React from "react";
import { Machine } from "../classes/Machine";
import { Col, Container, Row } from "react-bootstrap";
import { WmkSeo } from "wmk-seo";
import GradientAngleHeader from "../components/layout/GradientAngleHeader";
import { useState } from "react";
import Layout from "../components/layout/MainLayout";
import { colors } from "../components/ui/variables";
import QuoteButton from "../components/ui/QuoteButton";
import Bullets from "../components/ui/Bullets";
import BasicTable from "../components/ui/BasicTable";
import ReactPlayer from "react-player";
import RelatedEquipment from "../components/ui/Equipment/EquipRelated";
import ImageCarousel from "../components/ui/ImageCarousel/ImageCarousel";
import { Bounce } from "react-awesome-reveal";
import styled from "styled-components";
import { MachinesQuery } from "../fragments/NodeMachinesFields";
import { MetaDataQuery } from "../fragments/NodeSiteMetaFields";
import { Img, WmkImage } from "wmk-image";

const QuoteCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 0vh 0 0rem 0;
  @media only screen and (max-width: 767px) {
    margin-top: 8vw !important;
  }
  @media only screen and (max-width: 350px) {
    position: relative;
    right: -2rem;
  }
`;

const MachinePage = ({
  data
}: {
  data: { machine: MachinesQuery; site: MetaDataQuery };
}) => {
  const machine = new Machine(data.machine);
  const images = machine.images;
  const [innerHeight, setInnerHeight] = useState();
  const features = machine.features;
  const formatFeatures = (features: string) => {
    let splitData = features.split("- ");
    splitData.shift();
    return splitData;
  };
  const formatImages = (images: Img[]) => {
    const preppedImages =
      Array.isArray(images) && images.length
        ? images.map((image) => {
            return () => <WmkImage image={image} />;
          })
        : null;
    return <ImageCarousel images={preppedImages} />;
  };

  const callFormatImages = () => {
    return formatImages(images);
  };

  const video = machine.videoLink;
  const apps = machine.applications;
  const specs = machine.specs;
  const isBlankSpecs =
    specs && specs.length === 1 && specs[0].key === "" && specs[0].value === "";
  return (
    <Layout>
      <WmkSeo.Meta
        title={machine.displayTitle}
        siteTitle={data.site.meta.title}
        baseUrl={data.site.meta.baseUrl}
        path={machine.link.to}
        slug={machine.slug}
        description={data.site.meta.description}
        twitterHandle={data.site.meta.twitterHandle}
        ogImageUrl={machine.thumbnails[0].url()}
        twitterImageUrl={machine.thumbnails[0].url()}
      />
      <GradientAngleHeader
        title={machine.displayTitle}
        text={machine.description}
        Aux={callFormatImages}
        height={innerHeight}
        setter={setInnerHeight}
      />
      <Container fluid style={{ padding: 0, background: colors.silver.hex }}>
        <Container style={{ color: colors.text.hex }}>
          <Row className="flex-column">
            <QuoteCol>
              <Bounce delay={1000} triggerOnce>
                <QuoteButton />
              </Bounce>
            </QuoteCol>
            <Col>
              <Bullets
                title="Features"
                bullets={features ? formatFeatures(features) : null}
              />
            </Col>

            {apps && apps.length > 0 ? (
              <Col style={{ paddingTop: features ? undefined : `6vh` }}>
                <BasicTable pairs={apps} title="Applications" />
              </Col>
            ) : null}
            {specs && specs.length > 0 && !isBlankSpecs ? (
              <Col>
                <BasicTable pairs={specs} title="Specifications" />
              </Col>
            ) : null}
            {video ? (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem 0"
                }}>
                <ReactPlayer url={video} />
              </Col>
            ) : null}
            <Col>
              <RelatedEquipment tags={machine.category} slug={machine.slug} />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default MachinePage;

export const query = graphql`
  query machineQuery($displayTitle: String) {
    machine: contentfulMachines(displayTitle: { eq: $displayTitle }) {
      ...NodeMachinesFields
    }
    site {
      ...NodeSiteMetaFields
    }
  }
`;
