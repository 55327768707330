import * as React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "../Typography";
import { colors } from "../variables";
import PepperButton from "../MainButton";
import { WmkLink } from "wmk-link";
import { Img, WmkImage } from "wmk-image";
import { HyperLink } from "../../../classes/HyperLink";

const StyledImage = styled(WmkImage)`
  :hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: 2;
`;

const TextWrapper = styled.div`
  margin: 1rem 1rem 1rem 0;
  @media only screen and (max-width: 600px) {
    h4 {
      font-size: 1.1rem !important;
    }
  }
`;

/**
 *
 * @param {Img} image - Img object
 * @param {string} title - Name of the equiptment
 * @param {array} apps - Array of applications (strings) for the quipment
 * @param {HyperLink} link - Link to the page for the equipment
 * @returns {JSX} - Displays an image, title, array of applications, and a button that links to the page for the item
 */
const EquipPreview = ({
  image,
  title,
  apps,
  link,
  related
}: {
  image: Img;
  title: string;
  apps: string[];
  link: HyperLink;
  related?: boolean;
}) => {
  return (
    <Row className="flex-column">
      <Col>
        {image ? (
          <WmkLink to={link.to}>
            <StyledImage image={image} />
          </WmkLink>
        ) : null}
        <TextWrapper>
          {!related ? (
            <Typography.H4
              style={{
                color: colors.blue.hex,
                marginBottom: "0.5rem"
              }}>
              {title}
            </Typography.H4>
          ) : (
            <Typography.H6
              style={{
                color: colors.blue.hex,
                marginBottom: "0.5rem"
              }}>
              {title}
            </Typography.H6>
          )}

          {Array.isArray(apps) && apps.length && (
            <>
              <Typography.P
                style={{
                  color: colors.blue.hex,
                  fontSize: !related ? undefined : `.8rem`
                }}>
                Applications include:
              </Typography.P>
              <Typography.P
                style={{
                  marginBottom: "1rem",
                  fontSize: !related ? undefined : `.8rem`
                }}>
                <>
                {apps.map((application, i) => {
                  if (i < apps.length - 1) {
                    return `${application}, `;
                  } else if (apps.length > 1) {
                    return `and ${application}`;
                  } else {
                    return `${application}`;
                  }
                })}
                </>
              </Typography.P>
            </>
          )}
          <div style={{ marginTop: "1rem", paddingBottom: "1rem" }}>
            <PepperButton to={link.to}>Learn More</PepperButton>
          </div>
        </TextWrapper>
      </Col>
    </Row>
  );
};

export default EquipPreview;
